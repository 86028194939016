import axios from "axios";

export const server = {
    // baseURL: "https://afl-api.devarka.com",
    baseURL: "https://api.acefantasyleague.com",
    // baseURL: "http://localhost:4000",
    // baseURL: "https://api-millionaires11.devarka.com"
};

export default axios.create({
    baseURL: server.baseURL
});