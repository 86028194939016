import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import TableHead from '../../Components/TableHead/TableHead';
import { MdModeEditOutline } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import { SeriesListAction, SeriesStatusUpdateAction } from '../../../features/CommonSlice';
import NoDataFound from "./../../Components/NoDataFound/NoDataFound";
import { Pagination } from "./../../Components/Hooks/Pagination"
import { HasConsecutiveSpaces, RemoveEmptyObjKey, debounceSearch } from '../../../utils/Function';
import { Link, useNavigate } from 'react-router-dom';
import CommonModal from '../../Components/Modal/CommonModal';
import SeriesEdit from './Edit';
import { BsEyeFill } from 'react-icons/bs';

const SeriesList = () => {
    const navigate = useNavigate();
    const [statusUpdate, setStatusUpdate] = useState(1)
    const [listDataArr, setListDataArr] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [perPageCount, setPerPageCount] = useState(30);
    const [searchString, setSearchString] = useState("");
    const [selectedTabID, setSelectedTabID] = useState(1);
    const [editSeriesModalOpen, setEditSeriesModalOpen] = useState(false);
    const [selectedSeriesData, setSelectedSeriesData] = useState("");

    useEffect(() => {
        let payload = {
            page: currentPage,
            pageSize: perPageCount,
            searchText: searchString,
            sports_id: selectedTabID
        }
        SeriesListAction(RemoveEmptyObjKey(payload), (response) => {
            if (response?.status === true) {
                setListDataArr(response)
            }
        })();
    }, [SeriesListAction, perPageCount, currentPage, searchString, selectedTabID, statusUpdate])

    const handleFilters = debounceSearch((e) => {
        if (HasConsecutiveSpaces(e.target.value)) return <></>;
        setSearchString(e.target.value)
    }, 300);

    const handleStatusUpdate = (id) => {
        SeriesStatusUpdateAction({ id }, (response) => {
            if (response?.status === true) {
                setStatusUpdate(statusUpdate + 1)
            }
        })();
    }

    let tableHeader = [
        { label: "#", key: "", sort: false },
        { label: "Series Name", key: "", sort: false },
        { label: "Series Short Name", key: "", sort: false },
        { label: "Matches", key: "", sort: false },
        { label: "Status", key: "", sort: false },
        { label: "Action", key: "", sort: false }
    ]
    return (
        <>
            <section className='mainSection'>

                <div className='title_breadcrumb_section'>
                    <div className='title_page'>Series List</div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Series list</li>
                        </ol>
                    </nav>
                </div>

                <div className='common_section_main'>

                <div className='tab_main_section_list'>
                    <Tabs defaultActiveKey={1} onSelect={(e) => setSelectedTabID(Number(e))}>
                        {["Cricket","Football"].map((value, index) => (
                            <Tab eventKey={index + 1} title={value} key={index}>
                                <div className='searchAddRowTable mt-5'>
                                    <div className='inputGroupSearch search_input'>
                                        <input type='text' className='form-control' placeholder='Search here ..' onChange={handleFilters} />
                                        <button type='button'><BiSearch /></button>
                                    </div>
                                </div>

                                <Table responsive className="themeTable">
                                    <TableHead data={tableHeader} />
                                    <tbody>
                                        {listDataArr?.data?.list?.length > 0 ?
                                            listDataArr?.data?.list?.map((value, index) => (
                                                <tr key={index}>
                                                    <td> {(currentPage - 1) * Number(listDataArr?.data?.perpage_count) + (index + 1)}. </td>
                                                    <td> {value?.name ? value?.name : "-"} </td>
                                                    <td> {value?.short_name ? value?.short_name : "-"} </td>
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className={`table_btn`}
                                                            onClick={() => navigate(`/admin/series-manager/${selectedTabID}/${value?.cid}/matches/list`)}
                                                        >
                                                            <BsEyeFill />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type='button'
                                                            className={`table_btn ${value?.platform_status == 1 ? "active" : "inactive"}`}
                                                            onClick={() => handleStatusUpdate(value.cid)}
                                                        >
                                                            {value?.platform_status == 1 ? "Active" : "Inactive"}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <div className="btnTableGroup">
                                                            <button type="button" onClick={() => {
                                                                setEditSeriesModalOpen(true)
                                                                setSelectedSeriesData(value)
                                                            }}>
                                                                <MdModeEditOutline />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                            :
                                            <>
                                                    <tr>
                                    <td colSpan={7} className='not_found_data_td'>
                                        <NoDataFound />
                                    </td>
                                </tr>
                                            </>
                                        }
                                    </tbody>
                                </Table>

                                {listDataArr?.data?.list?.length > 0 ?
                                    <div className='pagination_entries_section'>
                                        <div className='entries_text'>
                                            <select className='common_input_filed' onChange={(e) => setPerPageCount(e.target.value)}>
                                                <option value={30}>30</option>
                                                <option value={60}>60</option>
                                                <option value={80}>80</option>
                                                <option value={100}>100</option>
                                            </select>
                                        </div>

                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={listDataArr?.data?.totalcount}
                                            pageSize={listDataArr?.data?.perpage_count}
                                            onPageChange={(page) => setCurrentPage(page)}
                                        />
                                    </div>
                                    :
                                    null
                                }
                            </Tab>
                        ))}
                    </Tabs>
                </div>
                </div>

            </section>

            <CommonModal
                show={editSeriesModalOpen}
                onHide={() => setEditSeriesModalOpen(false)}
                title="Edit Series"

                body={
                    <SeriesEdit hide={setEditSeriesModalOpen} data={selectedSeriesData} setStatusUpdate={setStatusUpdate} />
                }
            />
        </>
    )
}

export default SeriesList